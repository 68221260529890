<template>
  <a-row  style="margin-bottom: 1.5rem" class='notification-item'>

      <a-col :md="2" :xs="5" >
        <div v-if='notification.type===1' class="notification-icon">
          <img  src='@/static/img_events/SEJ_Eventos_norificacion_generica.svg' alt='noti-icon' width='20' height='24'/>
        </div>
        <div v-else-if='notification.type===2' class="notification-icon icon-2 ">
          <img  src='@/static/img_events/Eventos_notificacion_aprobado.svg' alt='noti-icon' width='20' height='24'/>
        </div>
        <div v-else-if='notification.type===3' class="notification-icon icon-3">
          <img  src='@/static/img_events/Eventos_notificacion_rechazo.svg' alt='noti-icon' width='20' height='24'/>
        </div>
        <div v-else class="notification-icon">
          <img src='@/static/img_events/SEJ_Eventos_norificacion_generica.svg' alt='noti-icon' width='20' height='24'/>
        </div>

      </a-col>
      <a-col :md="20" :xs="18">
        <div class="notification-content d-flex">
          <div class="notification-text">
            <p class='notification__title'>{{notification.title}} </p>
            <p class='notification__description'>{{notification.description}} </p>
            <p class='notification__timeago'>{{formatDate (notification.created_at)}}</p>
          </div>

        </div>

      </a-col>
      <a-col :md="2" :xs="24" v-if='notification.url'>
        <div class='notification--button'>
          <span @click='handleClickNotification'>Detalle</span>
        </div>
      </a-col>
  </a-row>
  <notifications-config-modal
    :visible='visibleModal'
    :event-i-d='notification.url'
    @cancel-modal='visibleModal = false'
  />

</template>

<script>

import { timeAgo } from '@/utility/utility';
import NotificationsConfigModal from '@/components/notifications-config-modal/NotificationsConfigModal';

export default {
  name: 'NotificationItem',
  components: {
    NotificationsConfigModal
  },
  props:['notification'],
  data(){
    return {
      visibleModal: false
    }
  },
  methods:{
    formatDate(date){
      if(!date)
        return ''
      return timeAgo(date, true)
    },
    handleClickNotification(){

      if(this.notification.type == 4){
        this.visibleModal = true
        return;
      }
        if(this.notification.url.includes('/app')){
          const urlWithoutAppPrefix = this.notification.url.slice(4)
          this.$router.push(urlWithoutAppPrefix)
        } else if(this.notification.url.includes('app/')){
          const urlWithoutAppPrefix = this.notification.url.slice(3)
          this.$router.push(urlWithoutAppPrefix)
        }else{
          this.$router.push(this.notification.url);
        }
    },
  }
};
</script>

<style scoped lang='sass'>
.notification-item
  background: #FFFFFF
  box-shadow: 0px 3px 6px #00000029
  width: 100%
  padding: 21px 21px 12px 21px

  .notification-icon
    background: rgba(254, 198, 46, 0.2) 50% 50%
    color: #FEC62E
    width: 36px
    height: 36px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    &.icon-2
      background: rgba(232, 57, 77, 0.2) 50% 50%
      color: #E8394D
    &.icon-3
      background: rgba(232, 57, 77, 0.2) 50% 50%
      color: #51AF2C
  .notification-content
    .notification-text
      .notification__title
        text-align: left
        font-family: 'Nutmeg Bold'
        color: #606469
        font-size: 16px

      .notification__description
         text-align: left
         font-family: 'Avenir Roman'
         color: #606469
         font-size: 14px

      .notification__timeago
        text-align: left
        font-family: 'Avenir Book'
        color: #989898
        font-size: 12px
  .notification--button
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    width: 100%
    color: #3FA7E1
    font-family: "Avenir Heavy"
    @media only screen and (max-width: 700px)
      justify-content: flex-end
    span
      &:hover
        cursor: pointer



</style>