<template>
  <a-row  style="margin-bottom: 1rem" class='notification-list'>
    <template v-for='(notification, notiIndex) in notifications' :key='notiIndex' >
      <div :class="{'date-tag':notiIndex>0, 'date-tag first':notiIndex==0}" >
        {{
          notification.date === nearDates.today
            ? "Hoy"
            : notification.date === nearDates.yesterday
              ? "Ayer"
              : notification.date
        }}

      </div>


      <notification-item v-for='item in notification.items' :notification='item' :key='item.id' />
    </template>

  </a-row>


</template>

<script>


import NotificationItem from '@/view/notifications/overview/NotifiItem';
export default {
  name: 'Notifications',
  components:{
    NotificationItem,

  },
  mounted() {

  },
  props:['notifications','nearDates'],
  data:()=>{
    return{
      notificationsList: []
    }
  },
  methods:{


  }
};
</script>

<style lang='sass' scoped>
.notification-list
  .date-tag
    color: #606469
    font-family: 'Nutmeg Regular'
    font-size: 12px
    background: rgba(254, 198, 46, 0.2) 50% 50%
    width: 128px
    height: 20px
    text-align: center    
    border-radius: 8px
    margin-bottom: 21px
    &.first
      background: #FFFFFF
      text-align: left


</style>