<template>
  <a-row :gutter="25"  >
    <div class='notification-search'>
      <a-col :md="24" :xs="24" style='padding: 0' >
        <p class='title'>Búsqueda de notificaciones</p>
      </a-col>
      <a-col :md="24" :xs="24">
        <div class='search-content'>

          <a-row >
            <a-col :md="24" :xs="24">
                <a-input
                  v-on:keyup.enter='$emit("search:noti")'
                  v-model:value="filters.search"
                  placeholder="Buscar por palabra clave"
                  class="input-field"
                >
                  <template #prefix>
                    <sdFeatherIcons @click.stop='$emit("search:noti")' type="search" size="14" />
                  </template>
                </a-input>
            </a-col>
            <a-col :md="24" :xs="24">
              <a-form
                name='sDash_validation-form'
                ref='formRef'
                layout='horizontal'
              >
                <a-form-item  ref='filter_date' name='filter' label='Buscar por fecha' class='form-font mt-3'>
                  <a-date-picker
                    placeholder = 'DD / MM /AAAA'
                    class='filter_date'
                    v-model:value="filters.date"
                    @change='$emit("search:noti")'
                    format='YYYY/MM/DD'
                    style="padding: 0px !important;"
                  />
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>

        </div>


      </a-col>
    </div>

  </a-row>
</template>

<script>

import { toRef } from 'vue';

export default {
  name: 'SearchNoti',
  emits:['search:noti'],
  props:{
    filter: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  setup(props){
    const filters = toRef(props, 'filter');
    return{filters}

  },

};
</script>

<style lang='sass' scoped>
.notification-search
  background-color: #fff !important
  border-top: none
  padding: 0
  width: 100%
  .title
    padding: 21px 32px
    margin-bottom: 1rem
    font-family: 'Nutmeg Bold'
    color: #404040
    font-size: 16px
    border-bottom: 1px solid #CCCCCC

  .search-content
    padding: 21px 32px

.input-field
  border-radius: 30px

</style>
<style lang="sass">
.filter_date
  .ant-calendar-picker-input::placeholder
    color: #FFFFFF !important
  .ant-calendar-picker-icon
    svg
      fill: #FFFFFF
</style>