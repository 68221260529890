<template>
  <Main style="margin-top: 2rem">

    <a-row :gutter='1' style="margin-bottom: 1rem" >
      <a-col  :xxl="{span: 8, push: 16}" :lg="{span: 8, push: 16}" :xs="24" style="padding: 2rem">
        <search-noti
          :filter='filter'
          @search:noti='getNotifications'
        />
      </a-col>
      <a-col :xxl="{span: 16, pull: 8}" :lg="{span: 16, pull: 8}" :xs="24" style="padding: 2rem" >
        <div class='notification-history'>
          <a-row   class='title'>
            Historial de notificaciones
          </a-row>
          <a-row  class='' >
            <a-col :md="24" :xs="24">
              <notification-list
                v-if="groupedNotifications.length>0"
                :notifications='groupedNotifications'
                :near-dates='nearDates'
              />
              <span v-else class='no-events-label'>Aún no tienes notificaciones</span>
            </a-col>
          </a-row>
          <a-row :gutter="25">
            <a-col :lg="24">
              <!--show-size-changer-->
              <a-pagination
                v-if='groupedNotifications.length'
                v-model:page-size="pageSize"
                :total="totalNotifications"
                :current="currentPage"
                @change="handleChangePage"
                style="float: right"
              />
            </a-col>
          </a-row>

        </div>
      </a-col>
    </a-row>
  </Main>

</template>

<script>

import { useStore } from 'vuex';
import SearchNoti from '@/view/notifications/overview/SearchNoti';
import NotificationList from '@/view/notifications/overview/NotificationList';
import { getNotificationsHistory } from '@/services/notifications';
import { reactive } from 'vue';
import moment from 'moment';
export default {
  name: 'Notifications',
  components:{
    SearchNoti,
    NotificationList
  },
  setup(){
    const filter = reactive({
      date: null,
      search: ''
    })
    return{
      filter
    }
  },
  mounted() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', 'Notificaciones');
    this.handleDateAgroup()
    this.getNotifications()
  },
  data(){
    return{
      groupedNotifications: [],
      nearDates:{
        yesterday: null,
        today: null,
        tomorrow: null,
      },
      currentPage:1,
      pageSize:15,
      totalNotifications: 0,


    }
  },
  methods: {
    handleChangePage(current){
      this.currentPage = current
      this.getNotifications()
    },
    async getNotifications() {
      const  parseDate = this.filter.date ? moment(this.filter.date).format('YYYY-MM-DD') : ''
      await getNotificationsHistory(this.filter.search,parseDate, this.currentPage )
        .then(response =>{
        if(response.success){
          this.parseNotifications(response.notifications.data || [])
          this.totalNotifications = response.notifications.total || 0
        }
      })
      .catch(()=>{
      })


    },
    handleDateAgroup(){
      const monthsToNumber= {
        Jan: "01",
          Feb: "02",
          Mar: "03",
          Apr: "04",
          May: "05",
          Jun: "06",
          Jul: "07",
          Aug: "08",
          Sep: "09",
          Oct: "10",
          Nov: "11",
          Dec: "12",
      };
      let todayDate = new Date().toDateString();
      let today = todayDate.split(" ");

      let yesterdayDate = new Date(todayDate);
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      yesterdayDate = yesterdayDate.toDateString();
      let yesterday = yesterdayDate.split(" ");

      let tomorrowDate = new Date(todayDate);
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      tomorrowDate = tomorrowDate.toDateString();
      let tomorrow = tomorrowDate.split(" ");

      let data = {
        yesterday: `${yesterday[2]}/${monthsToNumber[yesterday[1]]}/${
          yesterday[3]
        }`,
        today: `${today[2]}/${monthsToNumber[today[1]]}/${today[3]}`,
        tomorrow: `${tomorrow[2]}/${monthsToNumber[tomorrow[1]]}/${tomorrow[3]}`,
      };
      this.nearDates=data;

    },
    parseNotifications(notifToParse) {
      let dates = [];
      let prevDate = "";
      //created_at: "2022-03-23T16:26:07.000000Z"


      const sortedNotif = notifToParse.sort(
        (a, b) =>
          new Date(b.created_at) -
          new Date(a.created_at)
      );

      sortedNotif.forEach((notification) => {

        let dateReceived = notification.created_at
          ? notification.created_at.split("T")
          : [];
        let timeSplitted = dateReceived[1].split(":"),
          dateSplitted = dateReceived[0].split("-"),
          auxDate = `${dateSplitted[2]}-${dateSplitted[1]}-${dateSplitted[0]}`,
          auxTime = `${timeSplitted[0]}:${timeSplitted[1]}`;
        dateReceived.splice(0, 1, auxDate);
        dateReceived.splice(1, 1, auxTime);

        let auxNotif = {
          ...notification,
          date: dateReceived[0],
          time: dateReceived[1],
        };
        if (auxNotif.date !== prevDate) {
          dates.push({ date: auxNotif.date, items: [{ ...auxNotif }] });
          prevDate = auxNotif.date;
        } else {
          dates.map((date) => {
            if (prevDate === date.date) {
              date.items.push({ ...auxNotif });
            }
          });
        }
      });

      this.groupedNotifications = dates
    }
  }
};
</script>

<style scoped lang='sass'>

.notification-history
  background-color: #FFFFFF
  border-radius: 8px
  padding: 32px
  .title
    margin-bottom: 1rem
    font-family: 'Nutmeg Bold'
    color: #404040
    font-size: 16px


</style>